import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Steps from "../components/steps"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";

class Agre3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      agre3Data:{name:'',phone:'',email:'',title:''}
    };
  }

  componentDidMount = async () => {
    const { showLoader, hideLoader } = this.props;
    if(!sessionStorage.version || sessionStorage.version!==1.4){
      sessionStorage.clear();
      sessionStorage.version=1.4;
    }
    if(!sessionStorage.agre3Data){
        sessionStorage.agre3Data = JSON.stringify(this.state.agre3Data)
    } 
    this.state.agre3Data = JSON.parse(sessionStorage.agre3Data)
    this.setState({agre3Data:JSON.parse(sessionStorage.agre3Data)});
    console.log(this.state.agre3Data)
    
    showLoader();
    hideLoader();
    
  }

  sugChange = async(value,type) =>{
    let agre3Data = this.state.agre3Data;
    agre3Data.title = value;
    this.setState({agre3Data:agre3Data});
   
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: true,infoError:"" });
  }
  submit = (model) => {
    const { showLoader, hideLoader} = this.props;
    showLoader();
    //model.title = this.state.agre3Data.title;
    sessionStorage.agre3Data = JSON.stringify(model);
    hideLoader();
    navigate("/agreement4"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
  }
  
   
  render() {
    const { agre3Data, canSubmit } = this.state;
    const { buttonLoader} = this.props;
    
   
    return (
      <Layout layoutType="wizard" >
        <SEO title="Dealer Agreement" keywords={[`dealer`, `agreement`, `contacts`]} />
        <section>
        <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h1>{'SYSTEMS ADMINISTRATOR CONTACT INFORMATION'}</h1>
                <p></p>
              </div>
            </div>

            <div className="body-content">
              <Formsy ref="agre2Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>

              <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                            <MyInput name="name" id="name" placeholder="Name"  value={agre3Data.name} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>
                    <div className="form-right">
                        <div className="form-label-group error">
                        {/* <label>Title</label>
                        <MyAuto value={agre3Data.title} options={['Mr.','Ms.','Mrs.']}  sugChange={(e) => this.sugChange(e, 'title')} name="title" /> */}
                        <MyInput name="title" id="title" placeholder="Title"  value={agre3Data.title} validationError="Please enter a valid title."
                                isLabelRequired={true} className="form-control" validations={{minLength: 1,maxLength:25}} required />
                        
                        </div>
                    </div>
                </div>
                

                <div className="form-container-inline">
                    <div className="form-left">
                        <div className="form-label-group error">
                        <MyInputMask name="phone" id="phone" placeholder="Phone" mask="999-999-9999" value={agre3Data.phone} isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." required />
                        
                        </div>

                    </div>
                    <div className="form-right">
                        <div className="form-label-group error">
                        <MyInput name="email" id="email" placeholder="Email"  value={agre3Data.email} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                   
                </div>


                <div className="btn-container">
                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                    </button>
                </div>

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
        </section>


      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Agre3);